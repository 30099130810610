<template>
  <div>
    <div class="row">
      <ResumeCard
        :number="total_annonces"
        title="TOTAL ANNONCES"
        text-color="text-primary"
        border-color="border-left-primary"
        icon="fas fa-calendar"
      ></ResumeCard>
      <ResumeCard
        :number="total_utilisateurs"
        title="TOTAL UTILISATEURS"
        text-color="text-info"
        border-color="border-left-info"
        icon="fas fa-calendar"
      ></ResumeCard>
      <ResumeCard
        :number="total_services"
        title="TOTAL SERVICES"
        text-color="text-warning"
        border-color="border-left-warning"
        icon="fas fa-calendar"
      ></ResumeCard>
      <ResumeCard
        :number="total_partenaires"
        title="TOTAL PARTENAIRES"
        text-color="text-success"
        border-color="border-left-success"
        icon="fas fa-calendar"
      ></ResumeCard>
    </div>
    <div class="card card-custom card-stretch gutter-b">
      <div class="card card-custom shadow">
        <div class="card-header flex-wrap border-0 pt-2 pb-0">
          <div class="card-title">
            <h3 class="card-label">Liste des 10 dernières annonces</h3>
          </div>
        </div>
        <b-card-body>
          <!--begin: Datatable-->
          <b-row>
            <b-col>
              <b-table
                bordered
                hover
                outlined
                :items="ads"
                :filter="filter"
                :per-page="perPage"
                :current-page="currentPage"
                :fields="fields"
                responsive="sm"
              >
                <template #cell(superficie)="data">
                  <p class="">{{ data.item.superficie }} m<sup>2</sup></p>
                </template>

                <template #cell(categorie)="data">
                  <p class="">{{ data.value }}</p>
                </template>

                <template #cell(valider)="data">
                  <p class="">{{ data.value }}</p>
                </template>

                <template #cell(ville_id)="data">
                  <p class="">{{ data.item.ville.nom }}</p>
                </template>

                <template #cell(user_id)="data">
                  <p class="">
                    {{ data.item.user.nom }} {{ data.item.user.prenoms }}
                  </p>
                </template>

                <template #cell(type_id)="data">
                  <h5>
                    <span class="badge badge-primary text-md-center ">{{
                      data.item.type.nom
                    }}</span>
                  </h5>
                </template>

                <template v-slot:cell(actions)="data">
                  <router-link
                    class="btn btn-info btn-sm mr-2"
                    :to="{ name: 'ad.show', params: { id: data.item.id } }"
                  >
                    <i class="fas fa-eye"></i
                  ></router-link>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <!--end: Datatable-->
        </b-card-body>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import ResumeCard from "@/components/ResumeCard";
import ApiService from "@/core/services/api.service";

export default {
  name: "Dashboard",
  components: { ResumeCard },
  data() {
    return {
      source: "ad",
      resource: "ad",
      ads: [],
      total_annonces: 0,
      total_utilisateurs: 0,
      total_services: 0,
      total_partenaires: 0,
      total: 0,
      // DataTable option
      filter: "",
      perPage: 10,
      currentPage: 1,
      fields: [
        {
          key: "id",
          label: "#"
        },
        {
          key: "code",
          label: "Réference"
        },
        {
          key: "categorie",
          label: "Categorie",
          formatter: value => {
            return value.charAt(0).toUpperCase() + value.slice(1);
          }
        },
        {
          key: "valider",
          label: "Validé(e)?",
          formatter: value => {
            return value === 1 ? String("Validé(e)") : String("Non validé(e)");
          }
        },
        {
          key: "ville_id",
          label: "Ville"
        },
        {
          key: "type_id",
          label: "Type"
        },
        {
          key: "user_id",
          label: "Auteur"
        },
        "actions"
      ]
    };
  },
  mounted() {
    this.$store
      .dispatch(SET_BREADCRUMB, [{ title: "Tableau de bord" }])
      .then(() => {
        this.$bvToast.toast(`Vous êtes maintenant connecté.`, {
          title: "Connexion réussie.",
          variant: "success",
          autoHideDelay: 5000
        });
      });
  },
  beforeMount() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      let vm = this;
      ApiService.get(this.source).then(function(response) {
        vm.ads = response.data.annonces;
        vm.ventes = response.data.ventes;
      });
      ApiService.get("statistiques").then(response => {
        vm.total_annonces = response.data.total_annonces;
        vm.total_utilisateurs = response.data.total_utilisateurs;
        vm.total_services = response.data.total_services;
        vm.total_partenaires = response.data.total_partenaires;
      });
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  }
};
</script>

<style scoped></style>
