<template>
  <div class="col-xl-3 col-md-6 mb-4">
    <div class="card shadow h-100 py-2" :class="borderColor">
      <div class="card-body">
        <div class="row no-gutters align-items-center">
          <div class="col mr-2">
            <div
              class="text-xs font-weight-bold text-uppercase mb-1"
              :class="textColor"
            >
              {{ title }}
            </div>
            <div class="h5 mb-0 font-weight-bold text-gray-800">
              {{ number }}
            </div>
          </div>
          <div class="col-auto">
            <i class="fa-2x text-gray-300" :class="icon"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResumeCard",
  props: ["number", "icon", "title", "textColor", "borderColor"]
};
</script>

<style scoped></style>
